// Generated from QueryLexer.g4 by ANTLR 4.13.2
// noinspection ES6UnusedImports,JSUnusedGlobalSymbols,JSUnusedLocalSymbols
import { ATNDeserializer, DFA, Lexer, LexerATNSimulator, PredictionContextCache, Token } from "antlr4";
class QueryLexer extends Lexer {
    constructor(input) {
        super(input);
        this._interp = new LexerATNSimulator(this, QueryLexer._ATN, QueryLexer.DecisionsToDFA, new PredictionContextCache());
    }
    get grammarFileName() { return "QueryLexer.g4"; }
    get literalNames() { return QueryLexer.literalNames; }
    get symbolicNames() { return QueryLexer.symbolicNames; }
    get ruleNames() { return QueryLexer.ruleNames; }
    get serializedATN() { return QueryLexer._serializedATN; }
    get channelNames() { return QueryLexer.channelNames; }
    get modeNames() { return QueryLexer.modeNames; }
    static get _ATN() {
        if (!QueryLexer.__ATN) {
            QueryLexer.__ATN = new ATNDeserializer().deserialize(QueryLexer._serializedATN);
        }
        return QueryLexer.__ATN;
    }
}
QueryLexer.AND = 1;
QueryLexer.OR = 2;
QueryLexer.NOT = 3;
QueryLexer.LPAREN = 4;
QueryLexer.RPAREN = 5;
QueryLexer.WHITESPACES = 6;
QueryLexer.TAG_ID_PREFIX = 7;
QueryLexer.TAG_NAME_PREFIX = 8;
QueryLexer.WORD_PATTERN_PREFIX = 9;
QueryLexer.WORD_LENGTH_PREFIX = 10;
QueryLexer.TAG_ID = 11;
QueryLexer.TAG_NAME = 12;
QueryLexer.WORD_PATTERN = 13;
QueryLexer.WORD_LENGTH_RANGE = 14;
QueryLexer.EOF = Token.EOF;
QueryLexer.TAG_ID_MODE = 1;
QueryLexer.TAG_NAME_MODE = 2;
QueryLexer.WORD_PATTERN_MODE = 3;
QueryLexer.WORD_LENGTH_MODE = 4;
QueryLexer.channelNames = ["DEFAULT_TOKEN_CHANNEL", "HIDDEN"];
QueryLexer.literalNames = [null, null,
    null, null,
    "'('", "')'",
    null, "'ti:'",
    "'tn:'", "'wp:'",
    "'wl:'"];
QueryLexer.symbolicNames = [null, "AND",
    "OR", "NOT",
    "LPAREN", "RPAREN",
    "WHITESPACES",
    "TAG_ID_PREFIX",
    "TAG_NAME_PREFIX",
    "WORD_PATTERN_PREFIX",
    "WORD_LENGTH_PREFIX",
    "TAG_ID", "TAG_NAME",
    "WORD_PATTERN",
    "WORD_LENGTH_RANGE"];
QueryLexer.modeNames = ["DEFAULT_MODE", "TAG_ID_MODE",
    "TAG_NAME_MODE", "WORD_PATTERN_MODE",
    "WORD_LENGTH_MODE",];
QueryLexer.ruleNames = [
    "AND", "OR", "NOT", "LPAREN", "RPAREN", "WHITESPACES", "TAG_ID_PREFIX",
    "TAG_NAME_PREFIX", "WORD_PATTERN_PREFIX", "WORD_LENGTH_PREFIX", "WHITESPACE",
    "NUM_CHAR", "ALPHANUM_CHAR", "ALPHANUM_PATTERN_CHAR", "TAG_ID", "TAG_NAME",
    "WORD_PATTERN", "WORD_LENGTH_RANGE",
];
QueryLexer._serializedATN = [4, 0, 14, 162, 6, -1, 6, -1,
    6, -1, 6, -1, 6, -1, 2, 0, 7, 0, 2, 1, 7, 1, 2, 2, 7, 2, 2, 3, 7, 3, 2, 4, 7, 4, 2, 5, 7, 5, 2, 6, 7, 6,
    2, 7, 7, 7, 2, 8, 7, 8, 2, 9, 7, 9, 2, 10, 7, 10, 2, 11, 7, 11, 2, 12, 7, 12, 2, 13, 7, 13, 2, 14, 7,
    14, 2, 15, 7, 15, 2, 16, 7, 16, 2, 17, 7, 17, 1, 0, 1, 0, 1, 0, 1, 0, 1, 0, 3, 0, 47, 8, 0, 1, 1, 1, 1,
    1, 1, 1, 1, 3, 1, 53, 8, 1, 1, 2, 1, 2, 1, 2, 1, 2, 3, 2, 59, 8, 2, 1, 3, 1, 3, 1, 4, 1, 4, 1, 5, 4, 5, 66,
    8, 5, 11, 5, 12, 5, 67, 1, 5, 1, 5, 1, 6, 1, 6, 1, 6, 1, 6, 1, 6, 1, 6, 1, 7, 1, 7, 1, 7, 1, 7, 1, 7, 1,
    7, 1, 8, 1, 8, 1, 8, 1, 8, 1, 8, 1, 8, 1, 9, 1, 9, 1, 9, 1, 9, 1, 9, 1, 9, 1, 10, 1, 10, 1, 11, 1, 11, 1,
    12, 1, 12, 1, 13, 1, 13, 1, 14, 4, 14, 105, 8, 14, 11, 14, 12, 14, 106, 1, 14, 1, 14, 1, 15, 4, 15,
    112, 8, 15, 11, 15, 12, 15, 113, 1, 15, 1, 15, 1, 16, 4, 16, 119, 8, 16, 11, 16, 12, 16, 120, 1,
    16, 1, 16, 1, 17, 4, 17, 126, 8, 17, 11, 17, 12, 17, 127, 1, 17, 1, 17, 1, 17, 1, 17, 4, 17, 134,
    8, 17, 11, 17, 12, 17, 135, 1, 17, 4, 17, 139, 8, 17, 11, 17, 12, 17, 140, 1, 17, 1, 17, 1, 17,
    1, 17, 1, 17, 1, 17, 1, 17, 4, 17, 150, 8, 17, 11, 17, 12, 17, 151, 1, 17, 4, 17, 155, 8, 17, 11,
    17, 12, 17, 156, 3, 17, 159, 8, 17, 1, 17, 1, 17, 0, 0, 18, 5, 1, 7, 2, 9, 3, 11, 4, 13, 5, 15, 6,
    17, 7, 19, 8, 21, 9, 23, 10, 25, 0, 27, 0, 29, 0, 31, 0, 33, 11, 35, 12, 37, 13, 39, 14, 5, 0, 1,
    2, 3, 4, 4, 2, 0, 9, 9, 32, 32, 1, 0, 48, 57, 5, 0, 45, 45, 48, 57, 65, 90, 95, 95, 97, 122, 5, 0,
    42, 42, 48, 57, 63, 63, 65, 90, 97, 122, 168, 0, 5, 1, 0, 0, 0, 0, 7, 1, 0, 0, 0, 0, 9, 1, 0, 0, 0,
    0, 11, 1, 0, 0, 0, 0, 13, 1, 0, 0, 0, 0, 15, 1, 0, 0, 0, 0, 17, 1, 0, 0, 0, 0, 19, 1, 0, 0, 0, 0, 21, 1,
    0, 0, 0, 0, 23, 1, 0, 0, 0, 1, 33, 1, 0, 0, 0, 2, 35, 1, 0, 0, 0, 3, 37, 1, 0, 0, 0, 4, 39, 1, 0, 0, 0,
    5, 46, 1, 0, 0, 0, 7, 52, 1, 0, 0, 0, 9, 58, 1, 0, 0, 0, 11, 60, 1, 0, 0, 0, 13, 62, 1, 0, 0, 0, 15, 65,
    1, 0, 0, 0, 17, 71, 1, 0, 0, 0, 19, 77, 1, 0, 0, 0, 21, 83, 1, 0, 0, 0, 23, 89, 1, 0, 0, 0, 25, 95, 1,
    0, 0, 0, 27, 97, 1, 0, 0, 0, 29, 99, 1, 0, 0, 0, 31, 101, 1, 0, 0, 0, 33, 104, 1, 0, 0, 0, 35, 111,
    1, 0, 0, 0, 37, 118, 1, 0, 0, 0, 39, 158, 1, 0, 0, 0, 41, 42, 5, 97, 0, 0, 42, 43, 5, 110, 0, 0, 43,
    47, 5, 100, 0, 0, 44, 45, 5, 38, 0, 0, 45, 47, 5, 38, 0, 0, 46, 41, 1, 0, 0, 0, 46, 44, 1, 0, 0, 0,
    47, 6, 1, 0, 0, 0, 48, 49, 5, 111, 0, 0, 49, 53, 5, 114, 0, 0, 50, 51, 5, 124, 0, 0, 51, 53, 5, 124,
    0, 0, 52, 48, 1, 0, 0, 0, 52, 50, 1, 0, 0, 0, 53, 8, 1, 0, 0, 0, 54, 55, 5, 110, 0, 0, 55, 56, 5, 111,
    0, 0, 56, 59, 5, 116, 0, 0, 57, 59, 5, 33, 0, 0, 58, 54, 1, 0, 0, 0, 58, 57, 1, 0, 0, 0, 59, 10, 1,
    0, 0, 0, 60, 61, 5, 40, 0, 0, 61, 12, 1, 0, 0, 0, 62, 63, 5, 41, 0, 0, 63, 14, 1, 0, 0, 0, 64, 66, 3,
    25, 10, 0, 65, 64, 1, 0, 0, 0, 66, 67, 1, 0, 0, 0, 67, 65, 1, 0, 0, 0, 67, 68, 1, 0, 0, 0, 68, 69, 1,
    0, 0, 0, 69, 70, 6, 5, 0, 0, 70, 16, 1, 0, 0, 0, 71, 72, 5, 116, 0, 0, 72, 73, 5, 105, 0, 0, 73, 74,
    5, 58, 0, 0, 74, 75, 1, 0, 0, 0, 75, 76, 6, 6, 1, 0, 76, 18, 1, 0, 0, 0, 77, 78, 5, 116, 0, 0, 78, 79,
    5, 110, 0, 0, 79, 80, 5, 58, 0, 0, 80, 81, 1, 0, 0, 0, 81, 82, 6, 7, 2, 0, 82, 20, 1, 0, 0, 0, 83, 84,
    5, 119, 0, 0, 84, 85, 5, 112, 0, 0, 85, 86, 5, 58, 0, 0, 86, 87, 1, 0, 0, 0, 87, 88, 6, 8, 3, 0, 88,
    22, 1, 0, 0, 0, 89, 90, 5, 119, 0, 0, 90, 91, 5, 108, 0, 0, 91, 92, 5, 58, 0, 0, 92, 93, 1, 0, 0, 0,
    93, 94, 6, 9, 4, 0, 94, 24, 1, 0, 0, 0, 95, 96, 7, 0, 0, 0, 96, 26, 1, 0, 0, 0, 97, 98, 7, 1, 0, 0, 98,
    28, 1, 0, 0, 0, 99, 100, 7, 2, 0, 0, 100, 30, 1, 0, 0, 0, 101, 102, 7, 3, 0, 0, 102, 32, 1, 0, 0, 0,
    103, 105, 3, 27, 11, 0, 104, 103, 1, 0, 0, 0, 105, 106, 1, 0, 0, 0, 106, 104, 1, 0, 0, 0, 106, 107,
    1, 0, 0, 0, 107, 108, 1, 0, 0, 0, 108, 109, 6, 14, 5, 0, 109, 34, 1, 0, 0, 0, 110, 112, 3, 29, 12,
    0, 111, 110, 1, 0, 0, 0, 112, 113, 1, 0, 0, 0, 113, 111, 1, 0, 0, 0, 113, 114, 1, 0, 0, 0, 114, 115,
    1, 0, 0, 0, 115, 116, 6, 15, 5, 0, 116, 36, 1, 0, 0, 0, 117, 119, 3, 31, 13, 0, 118, 117, 1, 0, 0,
    0, 119, 120, 1, 0, 0, 0, 120, 118, 1, 0, 0, 0, 120, 121, 1, 0, 0, 0, 121, 122, 1, 0, 0, 0, 122, 123,
    6, 16, 5, 0, 123, 38, 1, 0, 0, 0, 124, 126, 3, 27, 11, 0, 125, 124, 1, 0, 0, 0, 126, 127, 1, 0, 0,
    0, 127, 125, 1, 0, 0, 0, 127, 128, 1, 0, 0, 0, 128, 129, 1, 0, 0, 0, 129, 130, 5, 46, 0, 0, 130,
    131, 5, 46, 0, 0, 131, 133, 1, 0, 0, 0, 132, 134, 3, 27, 11, 0, 133, 132, 1, 0, 0, 0, 134, 135,
    1, 0, 0, 0, 135, 133, 1, 0, 0, 0, 135, 136, 1, 0, 0, 0, 136, 159, 1, 0, 0, 0, 137, 139, 3, 27, 11,
    0, 138, 137, 1, 0, 0, 0, 139, 140, 1, 0, 0, 0, 140, 138, 1, 0, 0, 0, 140, 141, 1, 0, 0, 0, 141, 142,
    1, 0, 0, 0, 142, 143, 5, 46, 0, 0, 143, 144, 5, 46, 0, 0, 144, 159, 1, 0, 0, 0, 145, 146, 5, 46,
    0, 0, 146, 147, 5, 46, 0, 0, 147, 149, 1, 0, 0, 0, 148, 150, 3, 27, 11, 0, 149, 148, 1, 0, 0, 0,
    150, 151, 1, 0, 0, 0, 151, 149, 1, 0, 0, 0, 151, 152, 1, 0, 0, 0, 152, 159, 1, 0, 0, 0, 153, 155,
    3, 27, 11, 0, 154, 153, 1, 0, 0, 0, 155, 156, 1, 0, 0, 0, 156, 154, 1, 0, 0, 0, 156, 157, 1, 0, 0,
    0, 157, 159, 1, 0, 0, 0, 158, 125, 1, 0, 0, 0, 158, 138, 1, 0, 0, 0, 158, 145, 1, 0, 0, 0, 158, 154,
    1, 0, 0, 0, 159, 160, 1, 0, 0, 0, 160, 161, 6, 17, 5, 0, 161, 40, 1, 0, 0, 0, 18, 0, 1, 2, 3, 4, 46,
    52, 58, 67, 106, 113, 120, 127, 135, 140, 151, 156, 158, 6, 6, 0, 0, 5, 1, 0, 5, 2, 0, 5, 3, 0,
    5, 4, 0, 4, 0, 0];
QueryLexer.DecisionsToDFA = QueryLexer._ATN.decisionToState.map((ds, index) => new DFA(ds, index));
export default QueryLexer;
