import 'bootstrap';
import fontawesome from '@fortawesome/fontawesome-free';
import DataTable from 'datatables.net-bs4';
import 'datatables.net-buttons-bs4';
import 'datatables.net-select-bs4';
import './dataTables.altEditor.free';
import { setupEditionEnabled } from './setupEdition';
import { setupTagList } from './tagList';
import { setupWordList } from './wordList';
fontawesome.config = { autoReplaceSvg: false };
const defaults = DataTable.defaults;
defaults.info = false;
defaults.ordering = false;
defaults.paging = true;
defaults.searching = true;
const defaultsDom = DataTable.Buttons.defaults.dom;
defaultsDom.container.className = 'dt-buttons btn-group';
defaultsDom.button.className = 'btn btn-secondary btn-sm';
window.setupEditionEnabled = setupEditionEnabled;
window.setupTagList = setupTagList;
window.setupWordList = setupWordList;
console.log('The \'site\' bundle has been loaded!');
